import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Snatch Balance 5×2`}</p>
    <p>{`Full Snatch 5×2\\@80%1rm`}</p>
    <p>{`then,`}</p>
    <p>{`10:00 amrap of:`}</p>
    <p>{`10-OHS’s (95/65)(rx+ 115/75)`}</p>
    <p>{`50-Double Unders`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      